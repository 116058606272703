import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import styles from './styles/CityMap.module.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const CityMap = ({ cityLat, cityLng, locations }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [cityLng, cityLat],
      zoom: 9,
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
  }, [cityLat, cityLng]);

  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current.flyTo({
      center: [cityLng, cityLat],
      essential: true,
      zoom: 9,
    });

    // Clear existing markers
    document.querySelectorAll('.marker').forEach(marker => marker.remove());

    // Add new markers
    locations.forEach(location => {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = 'url("https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png")'; // Specify your marker icon URL
      el.style.width = '40px'; // Marker size
      el.style.height = '40px';
      el.style.backgroundSize = '100%';

      if (location.name) {
        new mapboxgl.Marker(el)
          .setLngLat([location.lng, location.lat])
          .setPopup(new mapboxgl.Popup().setHTML(`<h3>${location.name}</h3>`))
          .addTo(mapRef.current);
      }

      new mapboxgl.Marker(el)
        .setLngLat([location.lng, location.lat])
        .addTo(mapRef.current);
    });
  }, [cityLat, cityLng, locations]);

  return <div ref={mapContainerRef} className={styles.mapContainer} />;
};

CityMap.propTypes = {
  cityLat: PropTypes.number.isRequired,
  cityLng: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    name: PropTypes.string,
  })),
};

CityMap.defaultProps = {
  locations: [],
};

export default memo(CityMap);
