import React, { useEffect, useState, memo } from 'react';
import styles from './BotInstruction.module.css';

const BotInstruction = () => {
    const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

    useEffect(() => {
        // Проверка, что объект window.Telegram.WebApp доступен
        if (window.Telegram && window.Telegram.WebApp) {
            // Вызов метода expand() для раскрытия WebApp на весь экран
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
            setIsTelegramWebApp(true);
        }
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.titleBlock}>
                <h1>AviaGPT</h1>
            </div>

            <div className={styles.sectionBlock}>
                <p className={styles.aboutTitleText}>О проекте</p>
                <p className={styles.defaultText}>Наш проект посвящен тому, чтобы сопровождать вас на каждом этапе путешествия, обеспечивая необходимую поддержку и информацию.</p>
                <p className={styles.defaultText}><b>До поездки:</b> Мы поможем вам в поиске билетов и планировании маршрута, учитывая ваши предпочтения и бюджет.</p>
                <p className={styles.defaultText}><b>Перед вылетом:</b> Мы напомним о предстоящем рейсе и обеспечим всей необходимой информацией, чтобы ваш вылет прошел максимально комфортно.</p>
                <p className={styles.defaultText}><b>После прибытия:</b> Мы предложим вам лучшие места для посещения и досуга в новом городе.</p>
                <p className={styles.defaultText}><b>Во время путешествия:</b> В любой момент вы можете обратиться к нам за советами и информацией о местах, которые вы хотели бы посетить, а также получить ответы на любые вопросы, связанные с вашим путешествием.</p>
            </div>

            <div className={styles.sectionBlock}>
                <h2 className={styles.feautureTitle}>Добавление авиабилетов</h2>
                <p className={styles.defaultText}>Вы можете добавить авиабилеты в наш бот, чтобы получать уведомления о предстоящих рейсах и всю необходимую информацию о них в удобном формате.</p>
                <p className={styles.defaultText}>Чтобы добавить билет, вы можете отправить в бот маршрутную квитанцию в формате PDF, отправить скриншот с данными о билете или ввести номер рейса и дату вылета вручную.</p>

                <img src="/images/instruction/screen1.jpg" alt="Добавление авиабилетов" className={styles.image} />

                <p className={styles.defaultText}>После добавления билета вы будете получать уведомления о предстоящем рейсе.</p>
                <p className={styles.defaultText}>Вы также сможете просмотреть информацию о билете в удобном виде.</p>

                <img src="/images/instruction/screen2.jpg" alt="Просмотр информации о билете" className={styles.image} style={{ border: '0.5px solid #d7d7d7' }} />
            </div>

            <div className={styles.sectionBlock}>
                <h2 className={styles.feautureTitle}>Интересные места</h2>
                <p className={styles.defaultText}>Мы поможем вам найти интересные места для посещения в новом городе.</p>
                <p className={styles.defaultText}>Вы можете запросить информацию о местах, которые вас интересуют, и получить подробное описание и фотографии.</p>

                <img src="/images/instruction/screen4.jpg" alt="Интересные места" className={styles.image} />
            </div>

            <div className={styles.sectionBlock}>
                <h2 className={styles.feautureTitle}>Планирование путешествий</h2>
                <p className={styles.defaultText}>Вы можете попросить бота спланировать ваше путешествие.</p>

                <img src="/images/instruction/screen5.jpg" alt="Планирование путешествий" className={styles.image} />
            </div>

            <div className={styles.sectionBlock}>
                <h2 className={styles.feautureTitle}>Важные вопросы о путешествии</h2>
                <p className={styles.defaultText}>Вы можете задать боту вопросы, которые вас интересуют о вашем путешествии.</p>

                <img src="/images/instruction/screen6.jpg" alt="Важные вопросы о путешествии" className={styles.image} />
                <img src="/images/instruction/screen7.jpg" alt="Важные вопросы о путешествии" className={styles.image} />
            </div>

            <div className={styles.sectionBlock}>
                <h2 className={styles.feautureTitle}>Поиск авиабилетов</h2>
                <p className={styles.defaultText}>Мы поможем вам найти и купить билеты на самолет.</p>
                <p className={styles.defaultText}>Вы можете запросить билеты на определенную дату и направление, а также узнать актуальные цены на билеты.</p>

                <img src="/images/instruction/screen8.jpg" alt="Поиск авиабилетов" className={styles.image} />

                <p className={styles.defaultText}><i>Пожалуйста, обратите внимание, что поиск билетов находится в стадии разработки и может не всегда работать корректно.</i></p>
            </div>

            <div className={styles.footerBlock} style={{ borderRadius: isTelegramWebApp ? '20px' : '0 0 20px 20px' }}>
                <h1>AviaGPT</h1>
            </div>
        </div>
    );
};

export default memo(BotInstruction);
