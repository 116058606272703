import React, { useEffect, useState, useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import Message from './Message';
import Suggestions from './Suggestions';

import styles from './styles/ChatContainer.module.css';

const ChatContainer = ({ sendServerMessage, chatMessages, setChatMessages, language }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  const inputRef = useRef(null);
  const chatRef = useRef(null);

  const sendChatMessage = useCallback(async messageContent => {
    // Обработка текстового сообщения
    setChatMessages(prevMessages => [...prevMessages, { sender: 'user', text: messageContent }]);
    sendServerMessage(messageContent);
  }, [setChatMessages, sendServerMessage]);

  const sendMessage = useCallback(() => {
    if (isLoading) {
      return;
    }

    const text = inputValue.trim();
    if (text) {
      setIsLoading(true);
      sendChatMessage(text, 'text');
      setInputValue('');
    }
  }, [inputValue, isLoading, sendChatMessage]);

  const handleKeyDown = useCallback(event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  }, [sendMessage]);

  useEffect(() => {
    if (chatMessages.length > 0 && chatMessages[chatMessages.length - 1].sender === 'user') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const selectSuggestion = useCallback(suggestion => {
    setIsLoading(true);
    sendChatMessage(suggestion);
    setInputValue('');

    inputRef.current.focus();
    const length = suggestion.length;
    inputRef.current.setSelectionRange(length, length);
  }, [sendChatMessage]);

  const { current: handleInputFocus } = useRef(() => {
    setShowSuggestions(true);
  });

  const { current: handleInputBlur } = useRef(() => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  });

  return (
    <div className={styles.chatContainer}>
      <div
        className={`
          ${chatMessages.length === 1 ? styles.chatMessagesBoxSingleMessage : styles.chatMessagesBox}
          ${chatMessages.length > 2 ? styles.chatMessagesBoxWithMessages : ''}
        `}
        ref={chatRef}
      >
        {chatMessages.map((message, index) => (
          <Message key={index} text={message.text} sender={message.sender} />
        ))}
        {isLoading && <p className={`${styles.chatMessageText} ${styles.chatMessageAnimation}`}>.</p>}
      </div>
      <div className={`
        ${styles.chatInputBox}
        ${chatMessages.length === 1 ? styles.chatInputBoxSingleMessage : styles.chatInputBoxWithMessages}
      `}>
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          placeholder={language === 'ru' ? "Начните вводить сообщение..." : "Start typing a message..."}
          className={styles.chatInput}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          onChange={event => setInputValue(event.target.value)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {chatMessages.length === 1 && inputValue.length === 0 && (
          <Suggestions onSelectSuggestion={selectSuggestion} showSuggestions={showSuggestions} language={language} />
        )}
      </div>
    </div>
  );
};

ChatContainer.propTypes = {
  sendServerMessage: PropTypes.func.isRequired,
  chatMessages: PropTypes.arrayOf(PropTypes.shape({
    sender: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  setChatMessages: PropTypes.func.isRequired,
  language: PropTypes.string,
};

ChatContainer.defaultProps = {
  language: 'ru',
};

export default memo(ChatContainer);
