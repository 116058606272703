import React, { useEffect, useContext, useCallback, useState, memo } from 'react';
import CityMap from './CityMap.js';
import ChatContainer from './ChatContainer.js';
import { LanguageContext } from '../../utils/LanguageContext.js';
import { sendChatMessage } from '../api.js';

import styles from './styles/App.module.css';

const botLink = 'aviagpt_bot?start=gompa60n';

function App() {
  const { language } = useContext(LanguageContext);
  const [chatId, setChatId] = useState('');

  // Делаем редирект на телеграмм бота после любого клика на странице (один раз)
  /* useEffect(() => {
    const redirect = () => {
      window.open(`https://t.me/${botLink}`, '_blank');
      document.removeEventListener('click', redirect);
    };

    document.addEventListener('click', redirect);

    return () => {
      document.removeEventListener('click', redirect);
    };
  }, []); */

  const [chatMessages, setChatMessages] = useState([
    {
      text: 'Как я могу помочь тебе с поиском билетов, планированием путешествия или ответом на вопросы в путешествии?',
      sender: 'bot',
    },
  ]);

  const [cityLat, setCityLat] = useState(55.751244); // Moscow latitude
  const [cityLng, setCityLng] = useState(37.618423); // Moscow longitude
  const [locations, setLocations] = useState([]);

  const generateChatId = useCallback(() => {
    return 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }, []);

  useEffect(() => {
    if (!chatId) {
      const newChatId = generateChatId();
      setChatId(newChatId);
    }
  }, [chatId, generateChatId]);

  useEffect(() => {
    if (language === 'en') {
      setChatMessages([
        {
          text: 'How can I help you with finding tickets, planning a trip, or answering travel questions?',
          sender: 'bot',
        },
      ]);

      setCityLat(40.712776); // New York latitude
      setCityLng(-74.005974); // New York longitude
    } else {
      setChatMessages([
        {
          text: 'Как я могу помочь тебе с поиском билетов, планированием путешествия или ответом на вопросы в путешествии?',
          sender: 'bot',
        },
      ]);

      setCityLat(55.751244); // Moscow latitude
      setCityLng(37.618423); // Moscow longitude
    }
  }, [language]);

  const sendMessage = useCallback(async message => {
    const response = await sendChatMessage({ chatId, text: message });
    console.log('response:', response);

    if (response.text) {
      setChatMessages(prevMessages => [...prevMessages, { sender: 'bot', text: response.text }]);

      if (response.lat && response.lng) {
        setCityLat(response.lat);
        setCityLng(response.lng);
      }

      if (response.locations) {
        setLocations(response.locations);
      }
    }
  }, [chatId]);

  return (
    <div className={styles.container}>
      <div className={styles.blockChat}>
        <ChatContainer
          sendServerMessage={sendMessage}
          chatMessages={chatMessages}
          setChatMessages={setChatMessages}
          language={language}
        />
      </div>

      <div className={styles.blockMap}>
        <div className={styles.logo}>
          <img
            src="../../images/icon2.png"
            alt="Logo"
            className={styles.logoImage}
            onClick={() => window.open(`https://t.me/${botLink}`, '_blank')}
          />
          <div className={styles.logoBorder1}></div>
          <div className={styles.logoBorder2}></div>
        </div>
        <CityMap cityLat={cityLat} cityLng={cityLng} locations={locations} />
      </div>
    </div>
  );
}

export default memo(App);
