import axios from 'axios';

const apiApp = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/app`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchFlightData = async ({ flightNumber, departureDate }) => {
  const response = await apiApp.get(`/flight/${flightNumber}/${departureDate}`);
  return response.data;
}
