import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/Suggestions.module.css';

const Suggestions = ({ onSelectSuggestion, showSuggestions, language }) => {
    const [suggestions, setSuggestions] = useState([
        "Полетели в Париж",
        "Хочу в путешествие в Италию",
        "Какие места посмотреть в Венеции",
        "Расскажи про Пирамиды в Египте",
    ]);

    useEffect(() => {
        if (language === 'en') {
            setSuggestions([
                "Let's go to Paris",
                "I want to travel to Italy",
                "What places to see in Venice",
                "Tell me about the Pyramids in Egypt",
            ]);
        } else {
            setSuggestions([
                "Полетели в Париж",
                "Хочу в путешествие в Италию",
                "Какие места посмотреть в Венеции",
                "Расскажи про Пирамиды в Египте",
            ]);
        }
    }, [language]);

    return (
        <div className={showSuggestions ? styles.suggestionsContainerVisible : styles.suggestionsContainer}>
            {suggestions.map((suggestion, index) => (
                <button
                    key={index}
                    className={styles.suggestion}
                    onClick={() => onSelectSuggestion(suggestion)}
                >
                    {suggestion}
                </button>
            ))}
        </div>
    );
};

Suggestions.propTypes = {
    onSelectSuggestion: PropTypes.func.isRequired,
    showSuggestions: PropTypes.bool,
    language: PropTypes.string.isRequired,
};

Suggestions.defaultProps = {
    showSuggestions: false,
};

export default memo(Suggestions);
