import React, { useState, useCallback, useRef, useEffect, useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import { fetchFlightData } from '../../api.js';
import { differenceInMinutes, parseISO, subMinutes } from 'date-fns';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns-tz';
import { Plane } from 'lucide-react';
import FlightMap from './FlightMap';

import styles from './styles/FlightInfo.module.css';

const TIME_TO_CHECK_IN_INTERNATIONAL = 60; // Время до вылета, когда заканчивается регистрация на международный рейс
const TIME_TO_CHECK_IN_DOMESTIC = 40; // Время до вылета, когда заканчивается регистрация на внутренний рейс
const TIME_TO_BOARD = 20; // Время до вылета, когда заканчивается посадка

// TODO: Сделать расчет таймеров по актуальным датам, если они есть (регистрация, посадка, вылет)

const FlightInfo = () => {
    const { flightNumber, departureDate } = useParams();

    const [flightData, setFlightData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [, forceUpdate] = useState();

    const flightDuration = useMemo(() => {
        if (!flightData) {
            return null;
        }

        const departureTime = parseISO(flightData.departureTime);
        const arrivalTime = parseISO(flightData.arrivalDate);

        return differenceInMinutes(arrivalTime, departureTime);
    }, [flightData]);

    const getFlightData = useCallback(async () => {
        try {
            const data = await fetchFlightData({ flightNumber, departureDate });
            console.log("Данные о рейсе:", data);
            setFlightData(data);
        } catch (error) {
            console.error("Ошибка при получении данных о рейсе:", error);
        } finally {
            setLoading(false);
        }
    }, [flightNumber, departureDate]);

    useEffect(() => {
        getFlightData();
    }, [getFlightData]);

    // Хук для обновления таймера в реальном времени
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Принудительное обновление состояния для перерисовки компонента
            forceUpdate(n => !n);
        }, 60000); // Обновлять каждую минуту

        return () => clearInterval(intervalId); // Очистка интервала при размонтировании компонента
    }, []);

    useEffect(() => {
        // Проверка, что объект window.Telegram.WebApp доступен
        if (window.Telegram && window.Telegram.WebApp) {
            // Вызов метода expand() для раскрытия WebApp на весь экран
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
        }
    }, []);

    const timerType = useMemo(() => {
        if (!flightData) {
            return null;
        }

        const now = new Date(new Date().toUTCString());
        const departureTime = parseISO(flightData.departureTime);
        const arrivalTime = parseISO(flightData.arrivalDate);
        const checkInCloses = flightData.isInternational ?
            subMinutes(departureTime, TIME_TO_CHECK_IN_INTERNATIONAL) : 
            subMinutes(departureTime, TIME_TO_CHECK_IN_DOMESTIC);
        const boardingEnds = subMinutes(departureTime, TIME_TO_BOARD);

        // Если до вылета больше 4 часов то ничего не показываем
        if (differenceInMinutes(departureTime, now) > 240) {
            return null;
        }

        if (now < checkInCloses) {
            return 'checkIn';
        } else if (now >= departureTime && now <= arrivalTime) {
            return 'inFlight';
        } else if (now > arrivalTime) {
            return null; // completed - полет завершен
        } else if (now > boardingEnds && now < departureTime) {
            return 'inFlight'; // посадка закончилась, показываем время до прилета
        } else {
            return 'boarding';
        }
    }, [flightData]);

    const renderTimer = useCallback(() => {
        if (!flightData || !timerType) {
            return null;
        }

        const now = new Date(new Date().toUTCString());
        const departureTime = parseISO(flightData.departureTime);

        if (timerType === 'checkIn') {
            // Регистрация еще не закончилась
            const checkInCloses = flightData.isInternational ?
                subMinutes(departureTime, TIME_TO_CHECK_IN_INTERNATIONAL) :
                subMinutes(departureTime, TIME_TO_CHECK_IN_DOMESTIC);
            const minutesUntilClose = differenceInMinutes(checkInCloses, now);
            const hours = Math.floor(minutesUntilClose / 60).toString().padStart(2, '0');
            const minutes = (minutesUntilClose % 60).toString().padStart(2, '0');

            return (
                <div className={`${styles.flightTimer} ${styles.flightTimerRed}`}>
                    <h2>{`${hours}:${minutes}`}</h2>
                </div>
            );
        } else if (timerType === 'inFlight') {
            // Показываем таймер до прилета
            const arrivalTime = parseISO(flightData.arrivalDate);
            const minutesUntilArrival = differenceInMinutes(arrivalTime, now);
            const hours = Math.floor(minutesUntilArrival / 60).toString().padStart(2, '0');
            const minutes = (minutesUntilArrival % 60).toString().padStart(2, '0');

            return (
                <div className={`${styles.flightTimer} ${styles.flightTimerGrey}`}>
                    <h2>{`${hours}:${minutes}`}</h2>
                </div>
            );
        } else if (timerType === 'boarding') {
            // Регистрация закончилась, полет не начался, показываем таймер до посадки
            const boardingEnds = subMinutes(departureTime, TIME_TO_BOARD);
            const minutesUntilBoarding = differenceInMinutes(boardingEnds, now);
            const minutes = minutesUntilBoarding.toString().padStart(2, '0');

            return (
                <div className={`${styles.flightTimer} ${styles.flightTimerYellow}`}>
                    <h2>00:{minutes}</h2>
                </div>
            );
        }
    }, [flightData, timerType]);

    const { current: renderOnlyTime } = useRef((time, timeZone, actualTime = null, side = 'left') => {
        const date = parseISO(time);
        const formattedDate = format(date, 'HH:mm', { timeZone });
        const actualDate = parseISO(actualTime);

        // Если есть актуальное время и оно отличается от time, то показываем его
        if (actualTime && actualDate !== date) {
            const formattedActualDate = format(actualDate, 'HH:mm', { timeZone });
            const color = actualDate < date ? 'green' : 'red';

            return (
                <p className={styles.flightTime}>
                    {side === 'left' && <span style={{ color }}>{formattedActualDate} </span>}
                    <span className={styles.oldTime}>{formattedDate}</span>
                    {side === 'right' && <span style={{ color }}> {formattedActualDate}</span>}
                </p>
            );
        } else {
            return (
                <p className={styles.flightTime}>{formattedDate}</p>
            );
        }
    });

    const { current: renderDay } = useRef((time, timeZone) => {
        const date = format(parseISO(time), 'EEEEEE, dd MMM', { locale: ru, timeZone }); // Формат: "ПН, 18 МАР"
        return date.slice(0, -1).toUpperCase();
    });

    const { current: renderRuStatus } = useRef(status => {
        if (!status) {
            return null;
        }

        switch (status) {
            case 'scheduled':
                return 'По расписанию';
            case 'active':
                return 'В полёте';
            case 'landed':
                return 'Прибыл';
            case 'cancelled':
                return 'Отменен';
            case 'incident':
                return 'Инцидент';
            case 'diverted':
                return 'Перенаправлен';
            default:
                return status;
        }
    });

    const renderEndTime = useCallback(() => {
        if (!flightData || !timerType) {
            return null;
        }

        const departureTime = parseISO(flightData.departureTime);
        const checkInCloses = flightData.isInternational ?
            subMinutes(departureTime, TIME_TO_CHECK_IN_INTERNATIONAL) :
            subMinutes(departureTime, TIME_TO_CHECK_IN_DOMESTIC);
        const boardingEnds = subMinutes(departureTime, TIME_TO_BOARD);
    
        const formatTime = time => format(time, 'HH:mm', { timeZone: flightData.timeZoneDeparture });
    
        switch (timerType) {
            case 'checkIn':
                return `Регистрация заканчивается в ${formatTime(checkInCloses)}`;
            case 'boarding':
                return `Посадка заканчивается в ${formatTime(boardingEnds)}`;
            default:
                return null;
        }
    }, [flightData, timerType]);

    const renderFlyDuration = useCallback(() => {
        if (!flightData) {
            return null;
        }

        const departureTime = parseISO(flightData.actualDepartureDate || flightData.departureTime);
        const arrivalTime = parseISO(flightData.actualArrivalDate || flightData.arrivalDate);
        if (!departureTime || !arrivalTime) return null;
        if (arrivalTime < departureTime) return null;

        const minutesUntilArrival = differenceInMinutes(arrivalTime, departureTime);
        const hours = Math.floor(minutesUntilArrival / 60);
        const minutes = (minutesUntilArrival % 60);

        return (
            <div className={styles.flightDuration}>
                <p>{`${hours}ч ${minutes}мин`}</p>
            </div>
        );
    }, [flightData]);

    const { current: countryCodeToFlagEmoji } = useRef(isoCountryCode => {
        if (!isoCountryCode) return '';

        return isoCountryCode.toUpperCase().replace(/./g, char => 
            String.fromCodePoint(127397 + char.charCodeAt())
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles.firstBlock}>
                <div className={styles.flightContainer} style={{ justifyContent: timerType ? 'space-between' : 'center' }}>
                    <div className={styles.flightNumber}>
                        <h2>{flightNumber.slice(0, 2)} {flightNumber.slice(2)}</h2>
                    </div>
                    {!loading && flightData && renderTimer()}
                </div>

                {!loading && flightData && <>
                    <div className={styles.mapContainer}>
                        <FlightMap
                            departureLat={flightData.departureAirportData?.latitude}
                            departureLng={flightData.departureAirportData?.longitude}
                            arrivalLat={flightData.arrivalAirportData?.latitude}
                            arrivalLng={flightData.arrivalAirportData?.longitude}
                            flightDuration={flightDuration}
                            startTime={flightData.actualDepartureDate || flightData.departureTime}
                            timerType={timerType}
                        />
                    </div>
                </>}
            </div>

            <div className={styles.secondBlock}>
                {!loading && flightData && <>
                    <div className={styles.flightInfoContainer}>
                        <div className={styles.leftSide}>
                            {flightData.departureAirportData?.city &&
                                <p className={styles.flighText} style={{ margin: 0 }}>
                                    {countryCodeToFlagEmoji(flightData.departureAirportData.isoCountryCode)} {flightData.departureAirportData.city}
                                </p>
                            }
                        </div>
                        <div className={styles.rightSide}>
                            {flightData.arrivalAirportData?.city &&
                                <p className={styles.flighText} style={{ margin: 0 }}>
                                    {flightData.arrivalAirportData.city} {countryCodeToFlagEmoji(flightData.arrivalAirportData.isoCountryCode)}
                                </p>
                            }
                        </div>
                    </div>

                    <div className={styles.flightInfoContainer}>
                        <div className={styles.leftSide}>
                            <h3 className={styles.flightIata}>{flightData.departureIata}</h3>
                        </div>
                        <div className={styles.flightPlaneContainer}>
                            <div className={styles.flightPlanePath}></div>
                            <Plane size={26} className={styles.flightPlane} />
                            {renderFlyDuration()}
                        </div>
                        <div className={styles.rightSide}>
                            <h3 className={styles.flightIata}>{flightData.arrivalIata}</h3>
                        </div>
                    </div>

                    <div className={styles.flightInfoContainer}>
                        <div className={styles.leftSide}>
                            {renderOnlyTime(flightData.departureTime, flightData.timeZoneDeparture, flightData.actualDepartureDate, 'left' )}
                            <p className={styles.flighDate}>{renderDay(flightData.departureTime, flightData.timeZoneDeparture)}</p>
                        </div>
                        <div className={styles.rightSide}>
                            {renderOnlyTime(flightData.arrivalDate, flightData.timeZoneArrival, flightData.actualArrivalDate, 'right')}
                            <p className={styles.flighDate}>{renderDay(flightData.arrivalDate, flightData.timeZoneArrival)}</p>
                        </div>
                    </div>

                    <div className={styles.flightDescription} style={{ marginTop: '25px', border: 'none' }}>
                        <div className={styles.leftSide}>
                            <p className={styles.title}>{flightData.airline}</p>
                            <p className={styles.flighText}>{flightNumber.slice(0, 2)} {flightNumber.slice(2)}</p>
                        </div>
                        <div className={styles.rightSide}>
                            <p className={styles.title}>Статус</p>
                            <p className={`${styles.flighText} ${flightData.status === 'landed' ? styles.landedText : ''}`}>
                                {renderRuStatus(flightData.status)}
                            </p>
                        </div>
                    </div>

                    <div className={styles.flightDescription}>
                        <div className={styles.leftSide}>
                            <p className={styles.title}>Вылет</p>
                            <p className={styles.flighText}>{`${flightData.departureAirportData?.name || flightData.departureAirport} (${flightData.departureIata})`}</p>
                        </div>
                        <div className={styles.rightSide}>
                            {flightData.terminal &&
                                <p className={styles.flighText} style={{ margin: 0 }}>{`Терминал ${flightData.terminal}`}</p>
                            }
                            {flightData.gate &&
                                <p className={styles.flighText} style={{ marginTop: flightData.terminal ? '5px' : 0 }}>
                                    {`Гейт ${flightData.gate}`}
                                </p>
                            }
                        </div>
                    </div>

                    <div className={styles.flightDescription}>
                        <div className={styles.leftSide}>
                            <p className={styles.title}>Прилет</p>
                            <p className={styles.flighText}>{`${flightData.arrivalAirportData?.name || flightData.arrivalAirport} (${flightData.arrivalIata})`}</p>
                        </div>
                        <div className={styles.rightSide}>
                            {flightData.baggage &&
                                <p className={styles.flighText} style={{ margin: 0 }}>{`Выдача багажа: ${flightData.baggage}`}</p>
                            }
                        </div>
                    </div>

                    {timerType && (
                        <div className={styles.flightDescription}>
                            <div className={styles.leftSide}>
                                {(timerType === 'checkIn' || timerType === 'boarding') && <p className={styles.title}>
                                    {renderEndTime()}
                                </p>}
                                {(timerType === 'inFlight') && <p className={styles.title}>
                                    {`Прибытие в ${format(parseISO(flightData.arrivalDate), 'HH:mm', { timeZone: flightData.timeZoneArrival })} (местное время)`}
                                </p>}
                                <p className={styles.flighText}>
                                    {timerType === 'checkIn' ? 'До окончания регистрации:' :
                                    timerType === 'inFlight' ? 'До прибытия:' :
                                    'До окончания посадки:'}
                                </p>
                            </div>
                            <div className={styles.rightSide}>
                                {renderTimer()}
                            </div>
                        </div>
                    )}
                </>}
            </div>

            {loading && <p className={styles.text}>Загрузка данных о рейсе...</p>}
            {!loading && !flightData && <p className={styles.text}>Данные о рейсе не найдены.</p>}
        </div>
    );
};

export default memo(FlightInfo);
