import axios from 'axios';

const apiApp = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/site`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const sendChatMessage = async ({ chatId, text }) => {
  const response = await apiApp.post('/chat/send-message', { chatId, text });
  return response.data;
}
