import React, { memo } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

import styles from './styles/ChatContainer.module.css';

const Message = ({ text, sender }) => {
  // Создаем кастомный рендерер
  const renderer = new marked.Renderer();

  // Переопределяем метод рендеринга ссылок
  renderer.link = (href, title, text) => {
    return `<a href="${href}" title="${title || text}" target="_blank" rel="noopener noreferrer">${text}</a>`;
  };

  // Применяем кастомный рендерер
  marked.use({ renderer });

  // Преобразуем Markdown в HTML с учетом наших кастомизаций
  const sanitizedHTML = DOMPurify.sanitize(marked.parse(text));

  return (
    <div
      className={`${styles.chatMessageText} ${sender === 'user' ? styles.chatMessageUser : ''}`}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  );
};

Message.propTypes = {
  text: PropTypes.string.isRequired,
  sender: PropTypes.string.isRequired,
};

export default memo(Message);
