import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LanguageProvider } from '../utils/LanguageContext.js';

// app
import App from '../site/components/App';

// flight
import FlightInfo from '../app/components/flight/FlightInfo';
import BotInstruction from '../app/components/instructions/BotInstruction';

const AppRoutes = () => {
  return (
    <LanguageProvider>
      <Routes>
        <Route path="/" element={<App />} />

        <Route path="/flight/:flightNumber/:departureDate" element={<FlightInfo />} />
        <Route path="/instructions" element={<BotInstruction />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LanguageProvider>
  );
};

export default AppRoutes;
