import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import styles from './styles/FlightMap.module.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const LINE_COLOR = '#6267f2';

const FlightMap = ({ departureLat, departureLng, arrivalLat, arrivalLng, flightDuration, startTime, timerType }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: 'mapbox://styles/mapbox/streets-v11',
      // style: 'mapbox://styles/mapbox/light-v11',
      style: 'mapbox://styles/mapbox/dark-v11',
      center: [departureLng, departureLat],
      zoom: 4,
    });

    map.on('load', () => {
      map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              [departureLng, departureLat],
              [arrivalLng, arrivalLat],
            ],
          },
        },
      });

      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': LINE_COLOR,
          'line-width': 5,
        },
      });

      // Расчет угла поворота самолета
      const angle = Math.atan2(arrivalLng - departureLng, arrivalLat - departureLat) * (180 / Math.PI) - 51;

      // Расчет процента пройденного пути на момент открытия карты с учетом UTC
      const currentTimeUTC = new Date().getTime(); // Текущее время в UTC в миллисекундах
      const startTimeUTC = new Date(startTime).getTime(); // Время старта в UTC в миллисекундах
      const elapsedTime = (currentTimeUTC - startTimeUTC) / 3600000; // Время в часах с момента старта до текущего момента

      let progress = elapsedTime / (flightDuration / 60); // Прогресс полета в долях от 0 до 1

      if (progress < 0 && (timerType === 'inFlight' || timerType === 'boarding')) {
        progress = 0;
      }

      const currentPosition = [
        departureLng + progress * (arrivalLng - departureLng),
        departureLat + progress * (arrivalLat - departureLat),
      ];

      // Добавление иконку самолета
      if (progress >= 0 && progress <= 1) {
        map.loadImage('../../images/airplane.png', (error, image) => {
          if (error) throw error;

          map.addImage('plane', image);

          map.addSource('point', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: currentPosition,
                }
              }]
            }
          });

          map.addLayer({
            id: 'point',
            type: 'symbol',
            source: 'point',
            layout: {
              'icon-image': 'plane',
              'icon-size': 0.1,
              'icon-rotate': angle, // Поворот самолета
            }
          });
        });
      }

      // Автоматическое настройка области просмотра карты для отображения маршрута
      const bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(departureLng, departureLat),
        new mapboxgl.LngLat(arrivalLng, arrivalLat),
      );

      map.fitBounds(bounds, {
        padding: { top: 100, bottom: 100, left: 100, right: 100 }, // Добавляем отступы
        maxZoom: 4, // Максимальный зум для предотвращения слишком близкого приближения
        duration: 0, // Продолжительность анимации в миллисекундах
      });
    });

    return () => map.remove();
  }, [departureLat, departureLng, arrivalLat, arrivalLng, startTime, flightDuration, timerType]);

  return <div ref={mapContainerRef} className={styles.mapContainer} />;
};

FlightMap.propTypes = {
  departureLat: PropTypes.number.isRequired,
  departureLng: PropTypes.number.isRequired,
  arrivalLat: PropTypes.number.isRequired,
  arrivalLng: PropTypes.number.isRequired,
  flightDuration: PropTypes.number.isRequired, // Длительность полета в минутах
  startTime: PropTypes.string.isRequired, // Время вылета в формате строки по UTC, например, '2024-03-17T22:00:00Z'
  timerType: PropTypes.string,
};

FlightMap.defaultProps = {
  timerType: null,
};

export default memo(FlightMap);
